<template>
  <div class="page-wrapper theme-green-forest forgot-password-wrapper">
    <div class="logo-container">
      <router-link to="/">
        <img src="@/assets/icons/logo.svg" alt="logo" />
      </router-link>
    </div>
    <div class="login-back" v-if="!isEmailSent">
      <button @click="$router.back()" class="login-back__btn">
        <img src="@/static/settings/arrow.svg" alt="Arrow back" title="Back" />
      </button>
    </div>

    <div v-if="!isEmailSent" class="login">
      <div class="login__title account-title">forgotten Password</div>
      <div class="login__form-wrapper account-form-wrapper forgot-password">
        <form class="login__form" @submit.prevent="onSubmit">
          <div class="login__inputs">
            <div class="login__input-wrapper input-wrapper">
              <input
                type="email"
                placeholder="E-mail"
                v-model="email"
                class="with-icon"
                required
              />
            </div>
            <div class="login__input-wrapper input-wrapper">
              <input
                type="email"
                placeholder="Confirm E-mail"
                v-model="confirmEmail"
                class="with-icon"
                required
              />
              <p
                class="login__input-error error-message"
                v-if="!isEmailConfirm"
              >
                Confirm your E-mail
              </p>
            </div>
            <div class="input-wrapper--login">
              <button type="submit">
                <span class="text">Reset password</span>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="account-text">
        <span> No Account? </span>
        <router-link to="/registration" class="sign-up"> Sign Up </router-link>
      </div>
    </div>
    <CheckEmail v-else />
  </div>
</template>

<script>
import CheckEmail from '@/components/forgot-password/CheckEmail.vue'
import { userRecoveryPassword } from '@/services/user.service'
import { notification } from 'ant-design-vue'
export default {
  name: 'ForgotPassword',
  components: { CheckEmail },
  data() {
    return {
      isEmailSent: false,
      email: '',
      confirmEmail: '',
    }
  },
  computed: {
    isEmailConfirm() {
      return this.email === this.confirmEmail
    },
  },
  methods: {
    onSubmit() {
      if (!this.isEmailConfirm) return
      userRecoveryPassword(this.email)
        .then(() => {
          this.isEmailSent = true
        })
        .catch((err) => {
          // notification.error({ message: err.message })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.forgot-password {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.forgot-password-wrapper {
  .login {
    flex: 1;
    justify-content: flex-start;
  }

  .account-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 5%;
  }
}
</style>
