<template>
  <div class="login check-email top-96">
    <div class="login__title account-title">check your e-mail</div>
    <div class="login__form-wrapper account-form-wrapper">
      <p class="check-email__text">
        We sent a mail to your account e-mail. Click on the link in that mail to
        reset your password.
      </p>
      <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0_7223_1886)" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M56.666 28.332l-25 23.333-8.333-8.333"/><path d="M40 78.335c21.171 0 38.334-17.163 38.334-38.334C78.334 18.831 61.17 1.668 40 1.668c-21.17 0-38.333 17.162-38.333 38.333S18.829 78.335 40 78.335z"/></g><defs><clipPath id="clip0_7223_1886"><path fill="#fff" d="M0 0h80v80H0z"/></clipPath></defs></svg>
    </div>
    <div
      class="login__input-wrapper account-form-wrapper input-wrapper input-wrapper--login"
    >
      <button @click="$router.push('/')">
        <span class="text">Done</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckEmail',
}
</script>

<style lang="scss" scoped>
.check-email {
  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: var(--color-white);
    margin-bottom: 70px;
  }

  .account-title {
    margin-bottom: 20px;
  }

  .login__form-wrapper {
    text-align: center;
  }

  .login {
    flex: 1;
    justify-content: flex-start;
  }

  .login__input-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 5%;
  }
}
</style>
